



































































import { Component, Prop, Watch } from 'vue-property-decorator';
import BigNumberComponent from '@/components/cards/BigNumberComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { namespace } from 'vuex-class';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import FilterItemComponent from '@/components/FilterItemComponent.vue';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';
import DomainContext from '@/utils/enums/DomainContext';
import CategoryDomainContextEnum from '@/utils/enums/CategoryDomainContext';
import CategoryDomainContext from '@/models/graphql/CategoryDomainContext';
import PillWidget from '@/components/pill/PillWidget.vue';
import Variant from '@/utils/enums/Variant';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import CategorySelectorModal from '@/components/modals/CategorySelectorModal.vue';
import EntityType from '@/utils/enums/EntityType';
import Category from '@/models/graphql/Category';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

const categoryDomainContextStore = namespace('CategoryDomainContextStore');
const communityUserInterestStore = namespace('CommunityUserInterestStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    CategorySelectorModal,
    ButtonComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
    PillWidget,
    FilterItemComponent,
    BigNumberComponent,
  },
  inheritAttrs: false,
})
export default class MyProfileInterestsWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'MyProfileInterestsWidgetStore';

  private readonly modalCode = 'user-interests-selector-modal';

  private interestDomains: CategoryDomainContext[] = [];

  @Prop({ required: false, default: null })
  private readonly type!: string;

  @categoryDomainContextStore.Action
  private loadDomainsByMultipleContexts!: (contexts: string[]) => void;

  @categoryDomainContextStore.Getter
  private fetchContexts!: CategoryDomainContext[];

  @communityUserInterestStore.Action
  private updateUserInterest!: (payload: {
    categoriesToAdd: string[];
    interestsToDelete: string[];
    authUser: string;
  }) => void;

  @communityUserInterestStore.Getter
  private isLoading!: boolean;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private variantEnum = Variant;

  private EntityType = EntityType;

  private toAddUids: string[] = [];

  private toDeleteUids: string[] = [];

  private user: {
    uid: string;
    interests: CommunityUserInterest[];
  } = {
    uid: '',
    interests: [],
  };

  private get interests(): CommunityUserInterest[] {
    if (this.authUser && this.authUser.interests) {
      const tempArr = [] as CommunityUserInterest[][];
      this.interestDomains.forEach((i) => {
        if (this.authUser.interests) {
          tempArr.push(this.authUser.interests.filter((interest) => interest.interest?.domain === i.domain));
        }
      });
      return tempArr.flat(1);
    }
    return [];
  }

  private get sortedSelectedInterests(): { [key: string]: CommunityUserInterest[] } {
    const result: {
      [key: string]: CommunityUserInterest[];
    } = {};
    if (this.user.interests) {
      this.user.interests.forEach((interest: CommunityUserInterest) => {
        if (interest.interest && interest.interest.domain) {
          if (!result[interest.interest.domain]) {
            result[interest.interest.domain] = [];
          }
          result[interest.interest.domain].push(interest);
        }
      });
    }
    return result;
  }

  private get selectedIds(): string[] {
    const result: string[] = [];
    Object.entries(this.sortedSelectedInterests).forEach((item) => {
      item[1].forEach((interest) => {
        if (interest.interest) {
          result.push(interest.interest.uid);
        }
      });
    });
    return result;
  }

  created(): void {
    this.setDataConfig();
    this.loadDomainsByMultipleContexts([
      DomainContext.INTEREST_EXHIBITOR,
      DomainContext.INTEREST_SESSION,
    ]);
  }

  @Watch('isLoading')
  saveInterests(): void {
    if (!this.isLoading) {
      this.$bvModal.hide(this.modalCode);
      this.showToast(ToastActionType.UPDATE_PERSONAL_INFORMATION);
      this.toAddUids = [];
      this.toDeleteUids = [];
    }
  }

  @Watch('fetchContexts')
  watchSomeContext(): void {
    this.interestDomains = this.fetchContexts
      .filter((c) => c.context === CategoryDomainContextEnum.INTEREST_EXHIBITOR
        || c.context === CategoryDomainContextEnum.INTEREST_SESSION);
  }

  @Watch('isReadyToDisplay')
  @Watch('authUser.interests')
  setFormUser(): void {
    if (this.isReadyToDisplay) {
      this.user.uid = this.authUser.uid;
      this.user.interests = this.interests ? [...this.interests] : [];
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }

  private onClearInterest(interestId: string, index: number): void {
    let currentPillComponent = {} as PillComponent;

    currentPillComponent = this.$refs[`interest-${index}`] as PillComponent;
    currentPillComponent.disabledParam = true;

    if (this.interests) {
      const userInterest = this.user.interests
        .find((interest: CommunityUserInterest) => interest.interest
          && interest.uid === interestId);
      if (userInterest) {
        if (this.authUser.interests) {
          const findIndex = this.authUser.interests
            .findIndex((el: CommunityUserInterest): boolean => el.uid === interestId
              && this.interestDomains
                .findIndex((cd: CategoryDomainContext): boolean => cd.domain === el.interest?.domain) !== -1);
          this.authUser.interests.splice(findIndex, 1);
        }
        this.user.interests = this.authUser.interests;
        this.$store.dispatch('CommunityUserInterestStore/deleteUserInterest', userInterest.uid);
        this.showToast(ToastActionType.UPDATE_PERSONAL_INFORMATION);
        currentPillComponent.disabledParam = false;
      }
    }
  }

  private modalConfirm(payload: { selectedIds: string[]; selectedList: Category[] }): void {
    const toDeleteUid: string[] = [];
    const toAddUid: string[] = [];
    payload.selectedIds.forEach((uid) => {
      if (this.authUser.interests
        && this.authUser.interests.find((interest) => (interest.interest
          ? interest.interest.uid === uid
          : undefined)) === undefined) {
        toAddUid.push(uid);
      }
    });
    if (this.authUser.interests) {
      this.authUser.interests.forEach((interest) => {
        if (payload.selectedIds.find((uid) => (interest.interest
          ? interest.interest.uid === uid
          : undefined)) === undefined) {
          toDeleteUid.push(interest.uid);
        }
      });
    }
    this.toAddUids = toAddUid;
    this.toDeleteUids = toDeleteUid;
    this.updateUserInterest({
      categoriesToAdd: toAddUid,
      interestsToDelete: toDeleteUid,
      authUser: this.authUser.uid,
    });
    this.$emit('update-categories',
      {
        ids: payload.selectedList,
      });
  }
}
