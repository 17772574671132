enum DomainContext {
  USER_PROFILE_INTEREST = 'user_profile_interest',
  USER_PROFILE_SESSION_TOPICS_INTEREST = 'user_profile_session_topics_interest',
  SESSION_DETAIL = 'session_detail',
  COMPANY_DETAIL = 'company_detail',
  SESSION_FLAG = 'session_flag',
  DEAL_DETAIL = 'deal_detail',
  INTEREST_SESSION = 'interest_session',
  INTEREST_EXHIBITOR = 'interest_exhibitor'
}

export default DomainContext;
